<template>
  <div class="j_alert filebrowser type01 success floating">
    <div class='alert__contents'>
      <i></i>
      <p>
        <span>{{ $t('prompts.replace') }}</span>
        <small>{{ $t('prompts.replaceMessage') }}</small>
      </p>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <button 
        class="type01 sky j_button"
        style="position: static;"
        :aria-label="$t('buttons.replace')"
        :title="$t('buttons.replace')"
        @click="showConfirm"
      >
        {{ $t('buttons.replace') }}
      </button>
      <j-button class="type01" text @click="$store.commit('closeHovers')">Cancel</j-button>
    </v-card-actions>


  <!-- <div class="card floating">
    <div class="card-title">
      <h2>{{ $t('prompts.replace') }}</h2>
    </div>

    <div class="card-content">
      <p>{{ $t('prompts.replaceMessage') }}</p>
    </div>

    <div class="card-action">
      <button class="button button--flat button--grey"
        @click="$store.commit('closeHovers')"
        :aria-label="$t('buttons.cancel')"
        :title="$t('buttons.cancel')">{{ $t('buttons.cancel') }}</button>
      <button class="button button--flat button--red"
        @click="showConfirm"
        :aria-label="$t('buttons.replace')"
        :title="$t('buttons.replace')">{{ $t('buttons.replace') }}</button>
    </div>
  </div> -->

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'replace',
  computed: mapState(['showConfirm'])
}
</script>
