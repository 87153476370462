<template>
  <div class="j_alert filebrowser type01 success floating">
    <div class='alert__contents'>
      <i></i>
      <p>
        <span>{{ $t('prompts.newDir') }}</span>
        <small>Type new directory name</small>
        <input class="input input--block" type="text" @keyup.enter="submit" v-model.trim="name" v-focus>
      </p>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <j-button class="type01 sky" text @click="submit">Create</j-button>
      <j-button class="type01" text @click="$store.commit('closeHovers')">Cancel</j-button>
    </v-card-actions>


    <!-- <div class='alert__contents'>
      <div class="card-title">
        <h2>{{ $t('prompts.newDir') }}</h2>
      </div>

      <div class="card-content">
        <p>{{ $t('prompts.newDirMessage') }}</p>
        <input class="input input--block" type="text" @keyup.enter="submit" v-model.trim="name" v-focus>
      </div>


      <div class="card-action">
        <button
          class="button button--flat button--grey"
          @click="$store.commit('closeHovers')"
          :aria-label="$t('buttons.cancel')"
          :title="$t('buttons.cancel')"
        >{{ $t('buttons.cancel') }}</button>
        <button
          class="button button--flat"
          :aria-label="$t('buttons.create')"
          :title="$t('buttons.create')"
          @click="submit"
        >{{ $t('buttons.create') }}</button>
      </div>
    </div> -->
  </div>
</template>

<script>
import '../../assets/stylus/_prompt.styl'
import __C from '@/primitives/_constant_'

import { mapState, mapGetters, mapMutations } from 'vuex'
import { files as api } from '../../api'
import url from '../../utils/url'

export default {
  name: 'new-dir',
  data: function() {
    return {
      name: ''
    };
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']), 
    ...mapState(['fbname', 'req']),
    ...mapGetters([ 'isFiles', 'isListing' ]),
  },
  mounted() {
    //console.log('isFiles:', this.isFiles)  // 컴포넌트가 마운트되었을 때 isFiles 값 확인
  },
  methods: {
    ...mapMutations(['closeHovers', 'setDirection', 'updateRequest']),

    submit: async function(event) {
      // event.preventDefault()
      if (this.new === '') return
      
      // Build the path of the new directory.
      let uri = this.isFiles ? this.req.path + '/' : '/'
      if (!this.isListing) {
        uri = url.removeLastDir(uri) + '/'
      }
      uri += encodeURIComponent(this.name.replace(/[^0-9a-zA-Z\s]/g, '_')) + '/'
      uri = uri.replace('//', '/')
      try {
        await api.post(this.account.token, uri, this.fbname)
        this.$store.commit('updateRequest', await api.fetch(this.account.token,url.removeLastDir(uri), this.fbname))
        
      } catch (e) {
        this.$showError(e)
      }

      this.$store.commit('closeHovers')
    }
  }
};
</script>

