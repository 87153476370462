<template>
  <div>
    <h2 class="message">
      <i class="material-icons">gps_off</i>
      <span>{{ $t('errors.notFound') }}</span>
    </h2>
  </div>
</template>

<script>
export default {name: 'not-found'}
</script>

