<template>
  <div class="j_alert filebrowser type01 success floating">
    <div class='alert__contents'>
      <i></i>
      <p>
        <span>{{ $t('prompts.rename') }}</span>
        <small>{{ $t('prompts.renameMessage') }} <code>{{ oldName() }}</code></small>
        <input class="input input--block" type="text" @keyup.enter="submit" v-model.trim="name" v-focus>
      </p>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <j-button class="type01 sky" text @click="submit">Rename</j-button>
      <j-button class="type01" text @click="$store.commit('closeHovers')">Cancel</j-button>
    </v-card-actions>
  </div>
  <!-- <div class="card floating">
    <div class="card-title">
      <h2>{{ $t('prompts.rename') }}</h2>
    </div>

    <div class="card-content">
      <p>{{ $t('prompts.renameMessage') }} <code>{{ oldName() }}</code>:</p>
      <input class="input input--block" v-focus type="text" @keyup.enter="submit" v-model.trim="name">
    </div>

    <div class="card-action">
      <button class="button button--flat button--grey"
        @click="$store.commit('closeHovers')"
        :aria-label="$t('buttons.cancel')"
        :title="$t('buttons.cancel')">{{ $t('buttons.cancel') }}</button>
      <button @click="submit"
        class="button button--flat"
        type="submit"
        :aria-label="$t('buttons.rename')"
        :title="$t('buttons.rename')">{{ $t('buttons.rename') }}</button>
    </div>
  </div> -->
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapGetters, mapMutations } from 'vuex'
import url from '../../utils/url'
import { files as api } from '../../api'

export default {
  name: 'rename',
  data: function () {
    return {
      name: ''
    }
  },
  created () {
    this.name = this.oldName()
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']), 
    ...mapState(['fbname', 'req', 'selected', 'selectedCount']),
    ...mapGetters(['isListing'])
  },
  methods: {
    ...mapMutations(['setDirection']),

    cancel: function () {
      this.$store.commit('closeHovers')
    },
    oldName: function () {
      if (!this.isListing) {
        return this.req.name
      }

      if (this.selectedCount === 0 || this.selectedCount > 1) {
        // This shouldn't happen.
        return
      }

      return this.req.items[this.selected[0]].name
    },
    submit: async function () {
      let oldLink = ''
      let newLink = ''

      if (!this.isListing) {
        oldLink = this.req.url
      } else {
        oldLink = this.req.items[this.selected[0]].url
      }

      newLink = url.removeLastDir(oldLink) + '/' + encodeURIComponent(this.name)

      try {
        await api.move(this.account.token, this.fbname, [{ from: oldLink, to: newLink }])
        if (!this.isListing) {
          this.setDirection(newLink)
          return
        }

        this.$store.commit('setReload', true)
      } catch (e) {
        this.$showError(e)
      }

      this.$store.commit('closeHovers')
    }
  }
}
</script>
