import { FilebrowserApi } from './api'

export default class FilebrowserService {
    
  constructor() {
    this.filebrowserApi = new FilebrowserApi()
  }

  getFileResources (path, name='', sort={}) {
    return this.filebrowserApi.getFileResources(path, name, JSON.stringify(sort))
  }
  getFileRaw (path, name='') {
    return this.filebrowserApi.getFileRaw(path, name)
  }
}