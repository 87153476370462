<template>
  <nav class="active">
    <template>
      <div style="border: none;">
        <button @click="newDir" class="action" :aria-label="$t('sidebar.newFolder')" :title="$t('sidebar.newFolder')">
          <i class="material-icons">create_new_folder</i>
          <span>{{ $t('sidebar.newFolder') }}</span>
        </button>
      </div>
      <!-- <div>
        <button @click="$store.commit('showHover', 'newFile')" class="action" :aria-label="$t('sidebar.newFile')" :title="$t('sidebar.newFile')">
          <i class="material-icons">note_add</i>
          <span>{{ $t('sidebar.newFile') }}</span>
        </button>
      </div> -->
      <div v-if="recentDownloads.length > 0" class="recent_files_area">
        <div class="title">Recent Downloads Files</div>
        <v-list class="option_list">
          <div 
            v-for="(file, i) in recentDownloads" 
            :key="i"
            class="file_item"
          >
            <div :class="classRecentSelected(i)">
              <div class="file_item_wrap" @click="onDownload(file, i)">
                <div class="option_name w120" :title="file.name">{{ file.name }}</div>
                <div class="sub_name">{{ getRecentDays(file.date) }}</div>
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </template>
  </nav>
</template>

<script>
import __C from '@/primitives/_constant_'
import __M from 'moment'
import { mapState, mapMutations } from 'vuex'
import { files as api } from '../api'
import * as auth from '../utils/auth'

export default {
  name: 'sidebar',
  data: () => ({
    recentSelected: '-1'
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']), 
    ...mapState([ 'user' ]),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, ['recentDownloads']),
    
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'initService'
    ]),
    active () {
      return this.$store.state.show === 'sidebar'
    },
  },
  methods: {
    onDownload(file, idx) {
      this.recentSelected = idx
      api.download(this.account.token, null, file.fbname, file.fbpath)
      this.recentSelected = -1
    },
    
    classRecentSelected(idx) {
      return this.recentSelected == idx ? 'selected' : ''
    },
    getRecentDays(date) {
      let days = __M().diff(__M(date).toDate(), 'days')
      if(days == 1) return `${days} day`
      return `${days} days`
    },
    help() {
      this.$store.commit('showHover', 'help')
    },
    newDir() {
      this.$store.commit('showHover', 'newDir')
    },
    logout: auth.logout
  }
}
</script>
<style scoped>
.recent_files_area {
  padding-left: 1.8rem;
}
.recent_files_area .title {
  color: #BDBDBD;
  font-size: 11px !important;
  margin: 5px 0;
  margin-top: 10px;
}
.file_item_wrap {
  display: flex;
  padding: 0 8px;
  justify-content: space-between;
}
.file_item {
  height: 24px !important;
  color: #BDBDBD;
}
.file_item_wrap:hover {
  cursor: pointer;
  color: var(--blue) !important;
}
.selected {
  color: var(--blue) !important;
}
.option_name {
  font-size: 9px;
  width: 130px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.sub_name {
  font-size: 9px !important;
}
</style>
