<template>
  <div>
    <div id="breadcrumbs">
      <div class="left">
        <a :aria-label="$t('files.home')" :title="$t('files.home')" @click="setDirection('/')">
          <i class="material-icons">home</i>
        </a>

        <span v-for="(link, index) in breadcrumbs" :key="index">
          <span class="chevron"><i class="material-icons">keyboard_arrow_right</i></span>
          <span v-if="index===breadcrumbs.length-1">{{ escapeHtml(link.name) }}</span>
          <a v-else @click="setDirection(link.url)">{{ escapeHtml(link.name) }}</a>
        </span>
      </div>
      <!-- <div class="right">
        <v-radio-group v-model="rootDirection" row>
          <v-radio v-for="root in rootDirectionOptions" :key="root.index" :label="root.text" :value="root.value"></v-radio>
        </v-radio-group>        
      </div> -->
    </div>
    <div v-if="error">
      <not-found v-if="error.message === '404'"></not-found>
      <forbidden v-else-if="error.message === '403'"></forbidden>
      <internal-error v-else></internal-error>
    </div>
    <!-- <editor v-else-if="isEditor"></editor> -->
    <listing v-else-if="isListing" :class="{ multiple }"></listing>
    <preview v-else-if="isEditor||isPreview"
      @download-clicked="setFileBrowserValues"
    />
    <!-- <div v-else>
      <h2 class="message">
        <span>{{ $t('files.loading') }}</span>
      </h2>
    </div> -->
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import Forbidden from './errors/403'
import NotFound from './errors/404'
import InternalError from './errors/500'
import Preview from '../components/files/Preview'
import Listing from '../components/files/Listing'
import { FilebrowserService } from '../services'
import { mapGetters, mapState, mapMutations } from 'vuex'
import { FetchStreamService } from "@/services"

// function clean (path) {
//   return path.endsWith('/') ? path.slice(0, -1) : path
// }

export default {
  name: 'files',
  components: {
    Forbidden,
    NotFound,
    InternalError,
    Preview,
    Listing,
    // Editor: () => import('../components/files/Editor')
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),    
    ...mapGetters([
      'selectedCount',
      'isListing',
      'isEditor',
      'isFiles'
    ]),
    ...mapState([
      'req',
      'user',
      'reload',
      'multiple',
      'loading',
      'fbpath',
      'fbname',
      'sort'
    ]),
    isPreview () {
      return !this.loading && !this.isListing && !this.isEditor
    },
    breadcrumbs () {
      let parts = this.fbpath.split('/')

      if (parts[0] === '') {
        parts.shift()
      }

      if (parts[parts.length - 1] === '') {
        parts.pop()
      }

      let breadcrumbs = []

      for (let i = 0; i < parts.length; i++) {
        if (i === 0) {
          breadcrumbs.push({ name: decodeURIComponent(parts[i]), url: '/' + parts[i] })
        } else {
          breadcrumbs.push({ name: decodeURIComponent(parts[i]), url: breadcrumbs[i - 1].url + '/' + parts[i] })
        }
      }

      return breadcrumbs
    }
  },
  data: function () {
    return {
      fetchStreamService: null,
      filebrowserService: null,
      error: null,
      rootDirection: 'public',
      rootDirectionOptions: [
        { text: 'Web Public', value: 'public'},
        { text: 'Private Sharing', value: 'private'}
      ],
    }
  },
  created () {
    this.filebrowserService = new FilebrowserService()
    this.fetchStreamService = new FetchStreamService()
    this.fetchData()
  },
  watch: {
    'fbpath': 'fetchData',
    'reload': function () {
      this.fetchData()
    },
    fbname() {
      this.fetchData()
    }
  },
  mounted () {
    window.addEventListener('keydown', this.keyEvent)
    window.addEventListener('scroll', this.scroll)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.keyEvent)
    window.removeEventListener('scroll', this.scroll)
  },
  destroyed () {
    this.$store.commit('updateRequest', {})
  },
  methods: {
    ...mapMutations([ 'setLoading', 'setDirection' ]),

    fetchData () {
      // Reset view information.
      // this.$store.commit('setReload', false)
      this.$store.commit('resetSelected')
      this.$store.commit('multiple', false)
      this.$store.commit('closeHovers')

      // Set loading to true and reset the error.
      this.setLoading(true)
      this.error = null

      let url = this.fbpath
      if (url === '') url = '/'
      if (url[0] !== '/') url = '/' + url

      this.fetchStreamService.selectedFile(this.account.token, url, this.fbname, this.sort).then(res => {
        if (res.isDir) {
          res.items = res.items.map((item, index) => {
            item.index = index
            item.url = `${url}${url.length > 1 ? '/' : ''}${encodeURIComponent(item.name)}`
            return item
          })
        } 

        this.setLoading(false)

        this.$store.commit('updateRequest', res)
      })
      
    },

    setFileBrowserValues(v) {
      this.$emit('download-clicked', this.sanitizeDownloadData(v))
    },

    keyEvent (event) {
      // Esc!
      if (event.keyCode === 27) {
        this.$store.commit('closeHovers')

        // If we're on a listing, unselect all
        // files and folders.
        if (this.isListing) {
          this.$store.commit('resetSelected')
        }
      }

      // Del!
      if (event.keyCode === 46) {
        if (this.isEditor ||
          !this.isFiles ||
          this.loading ||
          (this.isListing && this.selectedCount === 0) ||
          this.$store.state.show != null) return

        this.$store.commit('showHover', 'delete')
      }

      // F1!
      if (event.keyCode === 112) {
        event.preventDefault()
        this.$store.commit('showHover', 'help')
      }

      // F2!
      if (event.keyCode === 113) {
        if (this.isEditor ||
          !this.isFiles ||
          this.loading ||
          !this.user.perm.rename ||
          (this.isListing && this.selectedCount === 0) ||
          (this.isListing && this.selectedCount > 1)) return

        this.$store.commit('showHover', 'rename')
      }

      // CTRL + S
      if (event.ctrlKey || event.metaKey) {
        if (this.isEditor) return

        if (String.fromCharCode(event.which).toLowerCase() === 's') {
          event.preventDefault()

          if (this.req.kind !== 'editor') {
            document.getElementById('download-button').click()
          }
        }
      }
    },
    scroll () {
      if (this.req.kind !== 'listing' || this.$store.state.user.viewMode === 'mosaic') return

      let top = 112 - window.scrollY

      if (top < 64) {
        top = 64
      }

      document.querySelector('#listing.list .item.header').style.top = top + 'px'
    },
    openSidebar () {
      this.$store.commit('showHover', 'sidebar')
    },
    openSearch () {
      this.$store.commit('showHover', 'search')
    },
    // 새로운 보안 관련 메서드들
    escapeHtml(unsafe) {
      return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;")
    },

    sanitizeUrl(url) {
      // URL 검증 및 정제 로직
      return encodeURI(url)
    },

    sanitizeFilename(filename) {
      if (!filename || typeof filename !== 'string') {
        return ''
      }
      // 파일명 검증 및 정제 로직
      //return filename.replace(/[^a-z0-9\.]/gi, '_')
      // 파일 확장자 분리
      const parts = filename.split('.')
      const ext = parts.length > 1 ? '.' + parts.pop() : ''
      let name = parts.join('.')

      // 허용된 파일 확장자 리스트
      const allowedExtensions = ['jpg', 'png', 'pdf', 'txt', 'svg', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', '7z', 'mp4', 'mov', 'avi', 'mp3']

      // 확장자가 허용된 것인지 확인
      if (ext && !allowedExtensions.includes(ext.slice(1))) {
        throw new Error('Disallowed file type.')
      } 

      // 파일명에서 허용되지 않는 문자 제거 또는 대체
      name = name.replace(/[<>:"/\\|?*]/g, '')

      // 제어 문자 제거 (ASCII 0-31 및 127)
      name = name.split('').filter(char => {
        const charCode = char.charCodeAt(0)
        return charCode > 31 && charCode !== 127
      }).join('')

      name = name.replace(/^\.+/, '')  // 맨 앞의 마침표 제거
      name = name.replace(/\s+/g, '_') // 공백을 밑줄로 대체
      
      // 파일명 길이 제한 (예: 255자)
      name = name.slice(0, 70 - ext.length - 1)
      if (name === '') {
        name = 'unnamed'
      }
      // 파일명과 확장자 결합
      return `${name}.${ext}`
    },

    // 새로운 함수: 폴더 경로 처리
    sanitizeFolderPath(path) {
      if (!path || typeof path !== 'string') {
        return ''
      }

      // 경로 구분자를 통일 (백슬래시를 슬래시로 변경)
      let sanitized = path.replace(/\\/g, '/')

      // 연속된 슬래시를 단일 슬래시로 변경
      sanitized = sanitized.replace(/\/+/g, '/')

      // 경로의 시작과 끝의 슬래시 제거 (루트 경로 '/' 제외)
      sanitized = sanitized.replace(/^\/+|\/+$/g, '')

      // 각 폴더명에 대해 간단한 살균 처리
      sanitized = sanitized.split('/').map(folder => {
        // 폴더명에서 허용되지 않는 특수문자 제거
        return folder.replace(/[<>:"|?*]/g, '').trim()
      }).filter(Boolean).join('/')

      return sanitized
    },

    sanitizeDownloadData(data) {
      // 다운로드 데이터 검증 및 정제 로직
      // 구체적인 구현은 data의 구조에 따라 달라집니다.
      return data
    }
  }

}
</script>

