<template>
  <div class="j_alert filebrowser type01 alert floating">
    <div class='alert__contents'>
      <i></i>
      <p>
        <span>Delete the File/Folder</span>
        <small v-if="req.kind !== 'listing'">{{ $t('prompts.deleteMessageSingle') }}</small>
        <small v-else>{{ $t('prompts.deleteMessageMultiple', { count: selectedCount }) }}</small>
      </p>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <j-button class="type01 pink" text @click="submit">Yes</j-button>
      <j-button class="type01" text @click="$store.commit('closeHovers')">Cancel</j-button>
    </v-card-actions>

  <!-- <div class="card floating">
    <div class="card-content">
      <p v-if="req.kind !== 'listing'">{{ $t('prompts.deleteMessageSingle') }}</p>
      <p v-else>{{ $t('prompts.deleteMessageMultiple', { count: selectedCount}) }}</p>
    </div>
    <div class="card-action">
      <button @click="$store.commit('closeHovers')"
        class="button button--flat button--grey"
        :aria-label="$t('buttons.cancel')"
        :title="$t('buttons.cancel')">{{ $t('buttons.cancel') }}</button>
      <button @click="submit"
        class="button button--flat button--red"
        :aria-label="$t('buttons.delete')"
        :title="$t('buttons.delete')">{{ $t('buttons.delete') }}</button>
    </div> -->
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import {mapGetters, mapMutations, mapState} from 'vuex'
import { files as api } from '../../api'
import url from '../../utils/url'
import buttons from '../../utils/buttons'

export default {
  name: 'delete',
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']), 
    ...mapGetters(['isListing', 'selectedCount']),
    ...mapState(['reload','fbname', 'fbpath', 'req', 'selected'])
  },
  methods: {
    ...mapMutations(['setReload','closeHovers', 'setDirection']),
    
    submit: async function () {
      this.closeHovers()
      buttons.loading('delete')
      try {
        if (!this.isListing) {
          await api.remove(`${this.req.path || this.fbpath}&name=${this.fbname}&token=${this.account.token}`,this.account.token)
          buttons.success('delete')
          this.setDirection(url.removeLastDir(this.req.path || this.fbpath))
          return
        }

        if (this.selectedCount === 0) {
          return
        }

        let promises = []
        for (let index of this.selected) {
          promises.push(api.remove(`${this.req.items[index].url}&name=${this.fbname}&token=${this.account.token}`,this.account.token))
        }

        await Promise.all(promises)
        buttons.success('delete')
        this.setReload(true)
      } catch (e) {
        buttons.done('delete')
        this.$showError(e)
        if (this.isListing) this.setReload(true)
      }
    }
  }
}
</script>
