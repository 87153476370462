<template>
  <div class="j_alert filebrowser type01 success floating">
    <div class='alert__contents'>
      <i></i>
      <p>
        <span>{{ $t('prompts.download') }}</span>
        <small>Downloads will be compressed in ZIP file format.</small>
      </p>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <j-button class="type01 sky" text @click="download('zip')">Download</j-button>
      <j-button class="type01" text @click="$store.commit('closeHovers')">Cancel</j-button>
    </v-card-actions>

  <!-- <div class="card floating" id="download">
    <div class="card-title">
      <h2 style="font-size: 18px;">{{ $t('prompts.download') }}</h2>
    </div>

    <div class="card-content">
      <p>{{ $t('prompts.downloadMessage') }}</p>

      <button class="button button--block" @click="download('zip')" v-focus>zip</button>
      <button class="button button--block" @click="download('tar')" v-focus>tar</button>
      <button class="button button--block" @click="download('targz')" v-focus>tar.gz</button>
      <button class="button button--block" @click="download('tarbz2')" v-focus>tar.bz2</button>
      <button class="button button--block" @click="download('tarxz')" v-focus>tar.xz</button>
      <button class="button button--block" @click="download('tarlz4')" v-focus>tar.lz4</button>
      <button class="button button--block" @click="download('tarsz')" v-focus>tar.sz</button>
    </div>
  </div> -->
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import '../../css/dashboard.css'

import {mapState, mapGetters} from 'vuex'
import { files as api } from '../../api'

export default {
  name: 'download',
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']), 
    ...mapState(['fbname', 'selected', 'req']),
    ...mapGetters(['selectedCount'])
  },
  methods: {
    download: function (format) {
      if (this.selectedCount === 0) return

      let files = []
      for (let i of this.selected) {
        files.push(this.req.items[i].url)
      }

      api.download(this.account.token, format, this.fbname, ...files)

      this.$store.commit('closeHovers')
    }
  }
}
</script>
